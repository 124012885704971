import type { Address, BI, Cell, Hash, Script, Transaction } from '@ckb-lumos/lumos'
import type { CotaInfo, IssuerInfo } from '@nervina-labs/cota-sdk'

import axios from 'axios'
import { JsonRpcSigner } from 'ethers'

import {
  createCluster,
  createSpore,
  meltSpore,
  SporeDataProps,
  transferCluster,
  transferSpore
} from '@spore-sdk/core'
import { commons, helpers } from '@ckb-lumos/lumos'
import { blockchain, bytes } from '@ckb-lumos/lumos/codec'
import { signRawTransaction, signTransaction as signTransferTx } from '@joyid/ckb'
import { getDexLockScript } from '@nervina-labs/ckb-dex'

import {
  APP_KEYS,
  CKB_NATION_FEE_ADDRESS,
  MIN_CKB_FEE,
  COLLECTION_FEE,
  COTA_REGISTRY_FEE,
  COTA_REGISTRY_RELAY_WALLET,
  NFT_STANDARDS,
  DEAD_ADDRESS,
  NETWORK,
  CHAIN_KEYS,
  ACTIVITY_CONSTANTS,
} from '../constants'
import { getIpfsHashFromJson } from '../ipfs'
import CkbController from './CkbController'
import { ensureMillisecondsFormat, getContractInfo } from '../helpers'
import CkbSync from './CkbSync'

type ScriptName =
  keyof (typeof CkbController.sporeConfig)['lumos']['SCRIPTS']

export async function mintItem(args: any, data: SporeMintArgs) {
  try {
    const fromLock = CkbController.getLock(data.address, data?.useLumos)
    const address = getCkbAddress(fromLock)

    data.fromLock = fromLock

    if (args.type === 'cluster') {
      return await mintCluster(address, data)
    } else if (args.type === NFT_STANDARDS.spore) {
      return await mintSpore(address, data)
    } else if (args.type === NFT_STANDARDS.cota) {
      return await mintCotaCollection(address, data)
    }
  } catch (error: any) {
    throw new Error(`Error minting ${args.type}: ${error?.message ?? 'Unknown'}`)
  }
}

async function mintCluster(userAddress: Address, data: SporeMintArgs) {
  try {
    const creator = userAddress

    let { txSkeleton, outputIndex } = await createCluster({
      data: {
        name: data.ipfsObject.name,
        description: data.ipfsObject.description,
      },
      toLock: data.fromLock,
      fromInfos: [creator],
      feeRate: 2000n,
      // config: CkbController.sporeConfig
    })

    txSkeleton = CkbController.prepareTransaction(txSkeleton, data?.activeWallet)
    txSkeleton = await CkbController.addFees(txSkeleton, { dev: BigInt(COLLECTION_FEE * 10 ** 8).toString() }, { currentUser: userAddress })

    let signedTx
    if (data.activeWallet === APP_KEYS.joyid) {
      signedTx = await signRawTransaction(
        // @ts-ignore
        helpers.createTransactionFromSkeleton(txSkeleton),
        creator // data2 not defined in joyid sdk
      )
    } else {
      signedTx = await signLumosTx(
        txSkeleton,
        data.fromLock,
        data.provider
      )
    }

    if (!signedTx) throw new Error('Issue with Signed Transaction!')

    const txHash = await CkbController.sendTransaction(signedTx)
    const outputs = txSkeleton.get('outputs')
    const clusterData = outputs.get(outputIndex)
    const clusterId = clusterData?.cellOutput.type?.args
    const createdAt = ensureMillisecondsFormat(data.ipfsObject?.createdAt) ?? Date.now()

    await axios.post('/add/collection', {
      standard: NFT_STANDARDS.spore,
      ipfsObject: data.ipfsObject,
      owner: creator,
      creator,
      address: clusterId,
      createdAt,
      updatedAt: createdAt,
      version: 2,
      isNation: true,
      isPublic: false,
      outPoint: {
        txHash,
        index: outputIndex ? `0x${BigInt(outputIndex).toString(16)}` : '0x0' // Assumption as DOB output is always first so far
      },
      txHash,
      capacity: clusterData?.cellOutput.capacity,
    })

    return { clusterId, outputIndex, txHash }
  } catch (error: any) {
    console.error('Error creating cluster!', error?.message)

    if (error?.message?.includes('enough capacity')) throw new Error(error?.message)
    else throw new Error('Error creating cluster!')
  }
}

async function mintSpore(userAddress: Address, data: SporeMintArgs) {
  try {
    const creator = userAddress

    let ipfsHash
    try {
      data.ipfsObject.creator = creator
      ipfsHash = await getIpfsHashFromJson(data.ipfsObject)
    } catch (error) {
      throw new Error('Error with creating IPFS Hash!')
    }

    if (!ipfsHash) throw new Error('No IPFS Hash detected!')
    if (!data.file) throw new Error('No file to be Minted!')

    const sporeContentData: SporeDataProps = {
      contentType: `${data.fileType};ipfs=${ipfsHash}`,
      content: data.file,
    }

    let clusterConfig

    if (data?.clusterId && data.clusterId.length > 4) {
      sporeContentData.clusterId = data.clusterId

      clusterConfig = {
        capacityMargin: (clusterCell: Cell, margin: BI) => {
          const lockArgs = clusterCell.cellOutput.lock.args.slice(46)
          const minCkb = Number(`0x${lockArgs.slice(0, 2)}`)

          // return 0
          return !isNaN(minCkb) ? margin.add(10 ** minCkb) : margin
        },
        /**
         * When referencing an ACP public Cluster,
         * the Cluster's corresponding witness should be set to "0x" (empty) and shouldn't be signed.
         */
        updateWitness: '0x',
      }
    }

    const sporeData = await createSpore({
      // txSkeleton,
      data: sporeContentData,
      toLock: data.fromLock,
      fromInfos: [creator],
      // capacityMargin: BigInt(1 * 10 ** 7), // 0.1 CKB
      // Was testing around an issue with bad Fee call, became unrelated, but this is used as a Prop internally in the Spore SDK and could be a valid config option
      // extraCapacity: BigInt(10 ** 4), // Extra 1000 Shannons for Gas
      // @ts-ignore
      cluster: clusterConfig,
      feeRate: 5000n,
    })

    let txSkeleton = CkbController.prepareTransaction(sporeData.txSkeleton, data?.activeWallet)
    txSkeleton = await CkbController.addFees(txSkeleton, { dev: BigInt(MIN_CKB_FEE * 10 ** 8).toString() }, { currentUser: userAddress })

    let signedTx
    if (data.activeWallet !== APP_KEYS.joyid) {
      signedTx = await signLumosTx(txSkeleton, data.fromLock, data.provider)
    } else {
      signedTx = await signRawTransaction(
        // @ts-ignore
        helpers.createTransactionFromSkeleton(
          txSkeleton,
          { validate: true }
        ),
        creator // data2 not yet defined in joyid sdk
      )
    }

    const txHash = await CkbController.sendTransaction(signedTx)

    const outputs = txSkeleton.get('outputs')
    const sporeCell = outputs.get(sporeData?.outputIndex)
    const sporeId = sporeCell?.cellOutput.type?.args
    const createdAt = ensureMillisecondsFormat(data.ipfsObject?.createdAt) ?? Date.now()

    if (!sporeId) throw new Error('Invalid output found, Spore not detected!')

    await axios.post('/add/item', {
      standard: NFT_STANDARDS.spore,
      ipfsObject: data.ipfsObject,
      tokenId: sporeId,
      clusterId: data?.clusterId,
      creator,
      file: sporeCell?.data,
      fileType: data.fileType,
      uri: `ipfs://${ipfsHash}`,
      totalAmount: 1,
      isNation: true,
      createdAt,
      updatedAt: createdAt,
      outPoint: {
        txHash,
        index: sporeData?.outputIndex ? `0x${BigInt(sporeData.outputIndex).toString(16)}` : '0x0' // Assumption as DOB output is always first so far
      },
      owners: [{
        address: creator,
        amount: '1',
        createdAt
      }],
      capacity: sporeCell?.cellOutput.capacity,
    })

    await CkbSync.addCellActivity({
      lockArgs: data.fromLock,
      tokenId: sporeId,
      collectionAddress: data?.clusterId ?? '0x1',
      txHash,
      id: 0,
      type: ACTIVITY_CONSTANTS.Mint,
      owner: creator,
      marketLockHash: '0x'
    })

    return { sporeId, outputIndex: sporeData?.outputIndex, txHash }
  } catch (error: any) {
    throw new Error(error?.message ?? '')
  }
}

export async function signLumosTx(
  txSkeleton: helpers.TransactionSkeletonType,
  fromLock: Script,
  signer: JsonRpcSigner,
): Promise<Transaction> {
  txSkeleton = commons.omnilock.prepareSigningEntries(txSkeleton, { config: CkbController.config })

  const inputs = txSkeleton.get('inputs')
  const outputs = txSkeleton.get('outputs')
  const signedWitnesses = new Map<string, string>()
  const signingEntries = txSkeleton.get('signingEntries')

  for (let i = 0; i < signingEntries.size; i += 1) {
    const entry = signingEntries.get(i)!
    if (entry.type === 'witness_args_lock') {
      const {
        cellOutput: { lock },
      } = inputs.get(entry.index)!
      const isSameScript = (
        script1: Script | undefined,
        script2: Script | undefined,
      ) => {
        if (!script1 || !script2) {
          return false
        }
        return (
          script1.codeHash === script2.codeHash &&
          script1.hashType === script2.hashType &&
          script1.args === script2.args
        )
      }

      // skip anyone-can-pay witness when cell lock not changed
      if (
        !isSameScript(lock, fromLock!) &&
        outputs.some((o) => isSameScript(o.cellOutput.lock, lock))
      ) {
        continue
      }

      const { message, index } = entry
      if (signedWitnesses.has(message)) {
        const signedWitness = signedWitnesses.get(message)!
        txSkeleton = txSkeleton.update('witnesses', (witnesses: { set: (arg0: any, arg1: string) => any }) => {
          return witnesses.set(index, signedWitness)
        })
        continue
      }

      let signature = await signer.signMessage(hexStringToUint8Array(message))

      // Fix ECDSA recoveryId v parameter
      // https://bitcoin.stackexchange.com/questions/38351/ecdsa-v-r-s-what-is-v
      let v = Number.parseInt(signature.slice(-2), 16)
      if (v >= 27) v -= 27
      signature = ('0x' +
        signature.slice(2, -2) +
        v.toString(16).padStart(2, '0')) as `0x${string}`


      const signedWitness = bytes.hexify(
        blockchain.WitnessArgs.pack({
          lock: commons.omnilock.OmnilockWitnessLock.pack({
            signature: bytes.bytify(signature!).buffer,
          }),
        }),
      )

      signedWitnesses.set(message, signedWitness)

      txSkeleton = txSkeleton.update(
        'witnesses',
        (witnesses: { set: (arg0: any, arg1: any) => any }) => {
          // return witnesses.set(entry.index, updateWitnessArgs(defaultEmptyWitnessArgs, 'lock', signedWitness))
          return witnesses.set(index, signedWitness)
        }
      )
    }
  }

  const signedTx = helpers.createTransactionFromSkeleton(txSkeleton)

  // const sealedTx = helpers.sealTransaction(txSkeleton, signedWitnesses.values)
  return signedTx
}

export function getScriptConfig(name: ScriptName) {
  const script = CkbController.sporeConfig.lumos.SCRIPTS[name]
  if (!script) {
    throw new Error(`Script ${name} not found`)
  }
  return script
}

export function isAnyoneCanPayScript(script: Script) {
  const anyoneCanPayLockScript = getScriptConfig('ANYONE_CAN_PAY')
  return (
    script.codeHash === anyoneCanPayLockScript.CODE_HASH &&
    script.hashType === anyoneCanPayLockScript.HASH_TYPE
  )
}

export function isOmnilockScript(script: Script) {
  const omnilockScript = getScriptConfig('OMNILOCK')
  return (
    script.codeHash === omnilockScript.CODE_HASH &&
    script.hashType === omnilockScript.HASH_TYPE
  )
}

export function isAnyoneCanPay(script: Script | undefined) {
  if (!script) {
    return false
  }
  if (isOmnilockScript(script)) {
    return script.args.slice(44, 46) === '02'
  }

  return isAnyoneCanPayScript(script)
}

export function getAnyoneCanPayMinimumCapacity(script: Script) {
  let minimumCkb = 0n

  if (!isAnyoneCanPay(script)) {
    return minimumCkb
  }

  // https://blog.cryptape.com/omnilock-a-universal-lock-that-powers-interoperability-1#heading-anyone-can-pay-mode
  if (isOmnilockScript(script)) {
    minimumCkb = BigInt(`0x${script.args.slice(46, 48)}`)
  }

  // https://github.com/nervosnetwork/rfcs/blob/master/rfcs/0026-anyone-can-pay/0026-anyone-can-pay.md#script-structure
  if (isAnyoneCanPayScript(script)) {
    if (script.args.length === 42) {
      return minimumCkb
    }

    minimumCkb = BigInt(`0x${script.args.slice(42, 44)}`)
  }

  return minimumCkb ? 10n ** minimumCkb : 0n
}

function hexStringToUint8Array(hexString: string) {
  // Remove the "0x" prefix if present
  hexString = hexString.startsWith('0x') ? hexString.slice(2) : hexString;

  // Ensure the string length is even
  if (hexString.length % 2 !== 0) {
    console.error('Invalid hex string');
    return hexString;
  }

  // Create a Uint8Array with the length of half the hex string length
  const byteArray = new Uint8Array(hexString.length / 2);

  // Loop through each pair of characters
  for (let i = 0, j = 0; i < hexString.length; i += 2, j++) {
    // Parse the hex string into an integer and add to the byte array
    byteArray[j] = parseInt(hexString.substring(i, i + 2), 16);
  }

  return byteArray;
}

export function getCkbAddress(lock: Script): Hash {
  return helpers.encodeToAddress(lock, { config: CkbController.config }) || ''
}

export async function getBalance(address: string) {
  return await CkbController.getBalance(address)
}

export async function getItemCapacity(item: any): Promise<bigint> {
  if (item?.chain !== CHAIN_KEYS.ckb) return 0n

  const typeScript = item?.standard === NFT_STANDARDS.spore ? CkbController.getSporeTypeScript() : null

  if (!typeScript || !item?.tokenId) throw new Error('Invalid request for getItemCapacity!')

  return BigInt(
    (await CkbController.rpc.getCellsCapacity({
      script: {
        ...typeScript,
        args: item.tokenId
      },
      scriptType: 'type'
    }))?.capacity
  )
}

export async function transferCkb(
  data: {
    fromAddress: Address
    toAddress: Address
    amount: BigInt
    useLumos: boolean
    signer?: JsonRpcSigner
  }
) {
  try {
    let signedTx

    if (data.useLumos && data?.signer) {
      let txSkeleton = helpers.TransactionSkeleton({ cellProvider: CkbController.indexer });
      txSkeleton = await commons.common.transfer(
        txSkeleton,
        ['<your-address>'],
        'ckt1qzda0cr08m85hc8jlnfp3zer7xulejywt49kt2rr0vthywaa50xwsqgy5rtexzvhk7jt7gla8wlq5lztf79tjhg9fmd4f',
        BigInt(100 * 10 ** 8),
      )

      txSkeleton = await commons.common.payFeeByFeeRate(
        txSkeleton,
        ['<your-address>'],
        1000,
      )

      signedTx = await signLumosTx(
        txSkeleton,
        CkbController.getLock(data.fromAddress, true),
        data.signer
      )
    } else {
      signedTx = await signTransferTx({
        to: data.toAddress,
        from: data.fromAddress,
        amount: BigInt(Number(data.amount) * 10 ** 8).toString(),
      })
    }

    if (!signedTx) throw new Error('Error with Signed Transaction!')

    await CkbController.sendTransaction(signedTx)
    console.info(`Sent Transaction - Hash: ${signedTx.hash}`)

    return true
  } catch (error: any) {
    throw new Error(`Error with Transfer CKB:: ${error?.message ?? ''}`)
  }
}

export async function payCkbFee(fromAddress: string, isLumos: boolean) {
  try {
    return await transferCkb({
      fromAddress: fromAddress,
      toAddress: CKB_NATION_FEE_ADDRESS,
      amount: BigInt(MIN_CKB_FEE),
      useLumos: isLumos,
    })
  } catch (error) {
    console.error('Error with Paying CKB Fee::', error)
    return false
  }
}

export async function transferNft(
  item: CollectionUiMetadata | ItemUiMetadata,
  user: { activeWallet: string, address: Address },
  transferData: {
    transferTo: Address,
    type: 'collection' | 'item',
    amount?: BigInt | string
    burn?: boolean
  },
  signer: any
) {
  try {
    const { amount, burn, transferTo } = transferData
    let transactionData

    let outPoint = item?.outPoint ?? {
      txHash: item?.txHash, // Fallback if outpoint was not saved
      index: item?.outPoint?.index ? `0x${BigInt(item.outPoint.index).toString(16)}` : '0x0' // Assumption as DOB output is always first
    }

    let txSkeleton


    if (item.standard === NFT_STANDARDS.spore) {
      try {
        if (burn || transferTo === DEAD_ADDRESS) {
          transactionData = await meltSpore({
            outPoint,
            changeAddress: user.address,
          })
        } else {
          if (transferData?.type === 'collection') {
            transactionData = await transferCluster({
              outPoint,
              toLock: CkbController.getLock(transferTo),
              fromInfos: [user.address]
            })
          } else {
            transactionData = await transferSpore({
              outPoint,
              toLock: CkbController.getLock(transferTo),
              feeRate: 5000n
            })

            txSkeleton = transactionData.txSkeleton.update('outputs', outputs => {
              const outputCell = outputs.get(0)

              if (!outputCell) throw new Error('No output Cell during Spore Transfer!')
              const newCellWithFeeRate: Cell = outputCell
              const newCapacityHex = (BigInt(outputCell.cellOutput.capacity) - BigInt(2000)).toString(16)

              newCellWithFeeRate.cellOutput.capacity = `0x${newCapacityHex}`

              return outputs.set(0, newCellWithFeeRate)
            })
          }
        }
      } catch (error: any) {
        throw new Error(`Error creating Transfer Cell for Spore:: ${error?.message ?? ''}`)
      }
    } else {
      console.log('Amount for CoTA', amount)
    }

    if (!transactionData) throw new Error('Problem creating Transfer Transaction!')

    const fromLock = CkbController.getLock(user.address, !!signer)


    txSkeleton = CkbController.prepareTransaction(transactionData.txSkeleton, user.activeWallet)

    if (!burn) {
      txSkeleton = await CkbController.addFees(txSkeleton, { dev: BigInt(MIN_CKB_FEE * 10 ** 8).toString() }, { currentUser: user.address })
    }

    let signedTx
    if (user.activeWallet === APP_KEYS.metamask) {
      signedTx = await signLumosTx(txSkeleton, fromLock, signer)
    } else if (user.activeWallet === APP_KEYS.joyid) { // JoyID assumption for now
      signedTx = await signRawTransaction(
        // @ts-ignore
        helpers.createTransactionFromSkeleton(txSkeleton),
        user.address // data2 not yet defined in joyid sdk
      )
    }

    if (!signedTx) throw new Error('Problem with signing Transaction!')

    const outputs = txSkeleton.get('outputs')
    let sporeCell

    const txHash = await CkbController.sendTransaction(signedTx)

    if (!burn) {
      // @ts-ignore
      sporeCell = outputs.get(transactionData?.outputIndex)

      outPoint = {
        txHash,
        // @ts-ignore
        index: `0x${BigInt(transactionData?.outputIndex).toString(16)}`
      }
    }

    if (!burn && 'tokenId' in item && sporeCell?.cellOutput.type?.args !== item.tokenId)
      throw new Error('TokenId mismatch!')

    return {
      updatedAt: Date.now(),
      outPoint
    }
  } catch (error) {
    throw error
  }
}

export async function updateIssuerInfo(userAddress: Address, data: any) {
  try {
    const issuer: IssuerInfo = {
      name: data.name,
      // description: `[${data.ipfsHash}]${data.description}`,
      description: data.description,
      avatar: data.image,
    }

    const result = await axios.post(`/generate/cota/issuer/${userAddress}`, { issuer, fee: COLLECTION_FEE, useLumos: data?.activeWallet === APP_KEYS.metamask })
    let txSkeleton = helpers.objectToTransactionSkeleton(result.data.txSkeleton)


    let signedTx
    if (data.activeWallet === APP_KEYS.joyid) {
      signedTx = await signRawTransaction(
        // @ts-ignore
        helpers.createTransactionFromSkeleton(txSkeleton),
        userAddress
      )
    } else {
      signedTx = await signLumosTx(txSkeleton, helpers.parseAddress(userAddress), data.signer)
    }

    const txHash = await CkbController.sendTransaction(signedTx)

    return txHash
  } catch (error) {
    console.error('Error with updating Issuer Info::', error)
    throw error
    throw new Error('Error with updating Issuer Info!')
  }
}

export async function mintCotaCollection(userAddress: Address, data: any) {
  try {
    const cotaInfo: CotaInfo & { symbol?: string } = {
      name: `${data.ipfsObject.name}`,
      image: data.ipfsObject.image,
      description:
        `[ipfs://${data.ipfsHash}]${data.ipfsObject.description}`
      // symbol: data.ipfsObject.symbol,
    }

    console.log('cotaInfo', cotaInfo)

    const result = await axios.post(`/generate/cota/collection/${userAddress}`, { cotaInfo, amount: data.amount })
    const cotaId = result.data?.cotaId
    if (!cotaId) throw new Error('No CoTA ID was generated!')

    let txSkeleton = helpers.objectToTransactionSkeleton(result.data.txSkeleton)
    // const rawTx = result.data.txSkeleton
    // rawTx.cellDeps.push(getJoyIDCellDep(NETWORK === 'mainnet'))

    txSkeleton = CkbController.prepareTransaction(txSkeleton, data.activeWallet)
    txSkeleton = await CkbController.addFees(txSkeleton, { dev: BigInt(COLLECTION_FEE * 10 ** 8).toString() }, { currentUser: userAddress })

    let signedTx
    if (data.activeWallet === APP_KEYS.joyid) {
      signedTx = await signRawTransaction(
        // @ts-ignore
        helpers.createTransactionFromSkeleton(txSkeleton),
        // txSkeleton,
        userAddress
      )
    } else {
      signedTx = await signLumosTx(txSkeleton, helpers.parseAddress(userAddress), data.signer)
    }


    console.log('signedTx', JSON.stringify(signedTx, null, 2))

    const txHash = await CkbController.sendTransaction(signedTx)

    // const collectionData = await checkCotaDefine(cotaId)
    // console.log('collectionData', collectionData)
    // if (collectionData.name === '') throw new Error('Error with new CoTA')

    const createdAt = Date.now()

    await axios.post('/add/collection', {
      standard: NFT_STANDARDS.cota,
      name: data.name,
      description: data.description,
      address: result.data.cotaId,
      creator: userAddress,
      image: data.image,
      uri: `ipfs://${data.ipfsHash}`,
      totalAmount: data.amount,
      createdAt,
      updatedAt: createdAt,
      outPoint: txSkeleton.get('inputs').get(0)?.outPoint,
      owner: userAddress,
      version: 2,
      isNation: true,
      txHash
    })

    return result.data.cotaId
  } catch (error) {
    console.error('Error minting CoTA Collection::', error)

    return false
  }
}

export async function checkCotaRegistry(userAddress: Address) {
  try {
    const { data } = await axios.get(`/check-cota/registry/${userAddress}`)

    return data?.registered ?? false
  } catch (error) {
    return false
  }
}

export async function checkCotaIssuer(userAddress: Address) {
  try {
    const { data } = await axios.get(`/check-cota/issuer/${userAddress}`)

    return data?.issuer ?? false
  } catch (error) {
    return false
  }
}

export const registerCotaCell = async (user: { signer: any; address: any; useLumos: any; activeWallet: any }) => {
  try {
    let response
    if (user?.activeWallet === APP_KEYS.joyid) {
      const { data } = await axios.post('/register-cota/joyid', {
        address: user.address
      })

      response = data
    } else if (user?.useLumos) {
      const { data } = await axios.post('/register-cota/lumos', {
        address: user.address
      })

      response = data

      const ownerLock = CkbController.getLock(user?.address, user?.useLumos)
      const signedTx = await signLumosTx(helpers.objectToTransactionSkeleton(data.txSkeletonObject), ownerLock, user.signer)

      if (!signedTx) throw new Error('Error with Signed Transaction!')

      response = await CkbController.sendTransaction(signedTx)

      return response
    }

    if (typeof response === 'string') return response

    if ((response?.message && response.message === 'Already Registered!')) {
      return true
    }
  } catch (error: any) {
    console.error('Error with register CoTA Cell::', error)
    throw new Error(`Error with register CoTA Cell::${error?.response?.data?.message ?? ''}`)
  }
}

export async function payCotaRegistryFee(user: { address: any }) {
  try {
    const signedTx = await signTransferTx({
      from: user.address,
      to: COTA_REGISTRY_RELAY_WALLET,
      amount: BigInt(COTA_REGISTRY_FEE * 10 ** 8).toString()
    })

    return await CkbController.sendTransaction(signedTx)
  } catch {
    throw new Error('Error paying CoTA Registry Fee')
  }
}

export async function mintCotaItems(cotaId: string, user: any, itemCharacteristics: { [key: number]: any }, signer?: any) {
  try {
    const { data } = await axios.post(`/generate/cota/mint/${cotaId}`, {
      address: user.address,
      itemCharacteristics
    })

    let txSkeleton = helpers.objectToTransactionSkeleton(data.txSkeletonObject)
    txSkeleton = CkbController.prepareTransaction(txSkeleton, user.activeWallet)
    txSkeleton = await CkbController.addFees(txSkeleton, { dev: BigInt(MIN_CKB_FEE * 10 ** 8).toString() }, { currentUser: user.address })

    let signedTx
    if (user?.activeWallet === APP_KEYS.joyid) {

      // @ts-ignore
      signedTx = await signRawTransaction(helpers.createTransactionFromSkeleton(txSkeleton), user.address)

    } else {
      const ownerLock = CkbController.getLock(user?.address, user?.useLumos)

      signedTx = await signLumosTx(txSkeleton, ownerLock, signer)

      if (!signedTx) throw new Error('Error with Signed Transaction!')
    }

    const txHash = await CkbController.sendTransaction(signedTx)

    const createdAt = Date.now()

    const tokenIds = []
    for await (const [index, characteristics] of Object.entries(itemCharacteristics)) {
      await axios.post('/add/item', {
        standard: NFT_STANDARDS.cota,
        itemCollection: cotaId,
        characteristics,
        tokenId: index,
        creator: user.address,
        totalAmount: 1,
        createdAt,
        updatedAt: createdAt,
        txHash,
        owners: [{
          address: user.address,
          amount: '1',
          createdAt
        }],
        userAddress: user.address,
        isNation: true
      })

      tokenIds.push(index)
    }

    return tokenIds
  } catch (error: any) {
    console.error('Error creating Mint Transaction::', error)
    throw new Error(`Error creating Mint Transaction::${error?.response?.data?.message ?? ''}`)
  }
}
