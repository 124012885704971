import { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { ACTIVITY_CONSTANTS, CHAIN_KEYS, NERVAPE_GEAR_CLUSTER_IDS, NETWORK, NFT_STANDARDS, SYMBOL_KEYS } from '../../utils/constants'
import { dateFormat, ensureMillisecondsFormat, prettyCommaFormat, tryAlternateUri } from '../../utils/helpers'
import AuthorAvatar from '../Author/AuthorAvatar'
import MediaWrapper from '../Media/MediaWrapper'
import NftChainLogo from './NftChainLogo'

import './nft.scss'

const NFT = (
  props: {
    data: { [key: string]: any },
    displayOwner?: boolean,
    tabView?: boolean
  }
) => {
  const { data: activity, displayOwner, tabView } = props
  const item = activity?.item ?? activity
  const decimals = item?.chain === CHAIN_KEYS.ckb ? 8 : 18

  function renderAuctionTimeLeft(startTime: string | number, endTime: string | number) {
    const now = Date.now()

    startTime = ensureMillisecondsFormat(startTime)
    endTime = ensureMillisecondsFormat(endTime)

    if (!startTime || !endTime) return null

    if (endTime < now) return 'Ended'
    if (startTime > now) return 'Not Started'

    const timeDifferenceMs = Number(endTime) - now
    const days = Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24))
    const hours = Math.floor(
      (timeDifferenceMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
    const minutes = Math.floor(
      (timeDifferenceMs % (1000 * 60 * 60)) / (1000 * 60)
    )

    return `${days} days; ${hours} hours; ${minutes % (24 * 60)} minutes remain.`
  }

  function getItemDescription(currentActivity: { [key: string]: any }) {
    const price = `${prettyCommaFormat(BigInt(currentActivity?.price ?? currentActivity?.startPrice ?? 0), decimals)} ${activity?.chain === CHAIN_KEYS.ckb ? SYMBOL_KEYS.ckb : SYMBOL_KEYS.godwoken}`
    const description = []

    switch (currentActivity.type) {
      case ACTIVITY_CONSTANTS.ListingCreated:
        description.push(
          <p key={currentActivity.id || 'listing'}><em>Listed</em> for <strong>{price}</strong></p>
        )
        break

      case ACTIVITY_CONSTANTS.ListingSold:
        description.push(
          <p key={currentActivity.id || 'purchase'}>
            Sold for <strong>{price}</strong></p>
        )
        break

      case ACTIVITY_CONSTANTS.ListingCancelled:
        description.push(
          <p key={currentActivity.id || 'delisting'}>Item priced at <em>{price}</em> has been <strong>Delisted</strong></p>
        )
        break

      case ACTIVITY_CONSTANTS.AuctionCreated:
        description.push(
          <div key={currentActivity.id || 'auction-created'}>
            <p>Auction Created!</p>
            <p>The lowest starting bid is <strong>{price}</strong></p>
            <p>Status: <strong>{renderAuctionTimeLeft(currentActivity?.startAt ?? currentActivity?.startTime, currentActivity?.endAt ?? currentActivity?.endTime)}</strong></p>
          </div>
        )
        break

      case ACTIVITY_CONSTANTS.AuctionCancelled:
        description.push(
          <p key={currentActivity.id || 'auction-cancelled'}>Auction <strong>{currentActivity.listingId}</strong> cancelled</p>
        )
        break

      case ACTIVITY_CONSTANTS.AuctionEnded:
        description.push(
          <p key={currentActivity.id || 'auction-ended'}>Auction ended, with the highest bid at <strong>{prettyCommaFormat(currentActivity.price, decimals)} {activity?.chain === CHAIN_KEYS.ckb ? SYMBOL_KEYS.ckb : SYMBOL_KEYS.godwoken}</strong></p>
        )
        break

      case ACTIVITY_CONSTANTS.BidCreated:
        description.push(
          <p key={currentActivity.id || 'bid-created'}><span>Bid placed for <strong>{prettyCommaFormat(currentActivity.price ?? 0, decimals)} {activity?.chain === CHAIN_KEYS.ckb ? SYMBOL_KEYS.ckb : SYMBOL_KEYS.godwoken}</strong></span> <span className='d-block'>on Auction {currentActivity.listingId}</span></p>
        )
        break

      case ACTIVITY_CONSTANTS.BidReplaced:
        description.push(
          <p key={currentActivity.id || 'bid-created'}><span>Bid placed for <strong>{prettyCommaFormat(currentActivity.price ?? 0, decimals)} {activity?.chain === CHAIN_KEYS.ckb ? SYMBOL_KEYS.ckb : SYMBOL_KEYS.godwoken}</strong></span> <span className='d-block'>on Auction {currentActivity.listingId} {currentActivity?.lastPrice && <>has out-bid previous bid of <strong>{prettyCommaFormat(currentActivity.lastPrice, decimals)} {activity?.chain === CHAIN_KEYS.ckb ? SYMBOL_KEYS.ckb : SYMBOL_KEYS.godwoken}</strong></>}</span></p>
        )
        break

      case ACTIVITY_CONSTANTS.BidWinner:
        description.push(
          <p key={currentActivity.id || 'bid-end'}><span className='d-block'>Auction {currentActivity.listingId} finalized!</span><span>Sold for <strong>{prettyCommaFormat(currentActivity.price ?? 0, decimals)} {activity?.chain === CHAIN_KEYS.ckb ? SYMBOL_KEYS.ckb : SYMBOL_KEYS.godwoken}</strong></span></p>
        )
        break

      case ACTIVITY_CONSTANTS.Transfer:
        description.push(
          <p key={currentActivity.id || 'minting'}><em>{currentActivity?.item?.name}</em> was <strong>transferred</strong></p>
        )
        break

      default:
      case ACTIVITY_CONSTANTS.Mint:
        description.push(
          <p key={currentActivity.id || 'minting'}><em>{currentActivity?.item?.name}</em> was <strong>Minted</strong> into the wild on {dateFormat(currentActivity.createdAt)}!</p>
        )
        break
    }

    return description
  }

  const renderItemTitle = useMemo(() => {
    const name = item?.name
    if (!name && item?.standard) return item.standard
    if (!name) return ''

    return name
  }, [item])

  const renderMedia = useMemo(() => {
    let image: string = item?.image

    if (!image) return null
    if (item?.assetType === 'audio' && typeof item?.coverImage === 'string') {
      image = item?.coverImage
    }

    return (<div className={NERVAPE_GEAR_CLUSTER_IDS[NETWORK].includes(item?.itemCollection) ? 'media is-nervape' : 'media'}>
      {item?.assetType !== 'audio' && <MediaWrapper src={image} assetType={item?.assetType ?? 'image'} file={item.file} fileType={item.fileType} />}
    </div>)
  }, [item])

  const renderActivityAction = (item: { [key: string]: any }) => {
    switch (item.type) {
      case ACTIVITY_CONSTANTS.ListingCreated:
        return 'Listed'

      case ACTIVITY_CONSTANTS.ListingSold:
        return 'Sold'

      case ACTIVITY_CONSTANTS.ListingCancelled:
        return 'Sale Cancelled'

      case ACTIVITY_CONSTANTS.AuctionCreated:
        return 'Auction Created'

      case ACTIVITY_CONSTANTS.AuctionCancelled:
        return 'Auction Cancelled'

      case ACTIVITY_CONSTANTS.AuctionEnded:
        return 'Auction Ended'

      case ACTIVITY_CONSTANTS.BidCreated:
      case ACTIVITY_CONSTANTS.BidReplaced:
        return 'New Bid Placed'

      case ACTIVITY_CONSTANTS.BidWinner:
        return 'Winning Bid'

      case ACTIVITY_CONSTANTS.Transfer:
        return 'Transferred'

      case ACTIVITY_CONSTANTS.Mint:
      default:
        return 'Minted'
    }
  }

  return (
    <div
      className={`item`.trimEnd()}
    >
      <h3 className='action'>{renderActivityAction(activity)}</h3>

      {!tabView && <>
        <h4 className='title'>
          {/* Possibly link to Chain page/main website */}
          <NftChainLogo standard={activity?.item?.standard ?? activity?.standard} />

          <Link style={{ color: 'inherit', textDecoration: 'none', fontSize: 'inherit', fontWeight: 'inherit' }} to={`/item-details/${activity?.itemCollection || activity?.collection}/${activity?.tokenId}`}>
            {renderItemTitle}
          </Link>
        </h4>
      </>}

      {!tabView && <Link
        to={`/item-details/${activity?.itemCollection || activity?.collection}/${activity?.tokenId}`}
        style={{ margin: '0 auto 0.5rem' }}
        className='image'
      >
        {renderMedia}
      </Link>}


      <div className='description'>
        {getItemDescription(activity)}
      </div>

      <Link className='btn btn-bordered btn-block linkTo' to={`/item-details/${activity?.itemCollection}/${activity?.tokenId}`}>View Item</Link>

      {displayOwner !== false && activity?.to &&
        <p className="to">
          <span className='label'>To </span>
          <AuthorAvatar author={activity?.to} />
        </p>
      }
    </div>)
}

export default NFT
