import React, { useEffect, useMemo, useRef, useState } from 'react'
import axios from 'axios'
import { getRenderReadyRoyalty, prettyCommaFormat } from '../../utils/helpers'
import { Analytics as IconAnalytics } from '@styled-icons/material/Analytics'
import { Storefront } from '@styled-icons/material/Storefront'
import AuthorAvatar from '../Author/AuthorAvatar'
import { CHAIN_KEYS, SYMBOL_KEYS } from '../../utils/constants'

import './analytics.scss'

const Analytics = (props: { data: any, type?: string }) => {
  const { data, type } = props

  const title = useMemo(() => (data?.tokenId ? 'Item' : 'Collection'), [data?.tokenId])
  const decimals = useMemo(() => (data?.chain === CHAIN_KEYS.ckb ? 8 : 18), [data?.chain])
  const symbol = useMemo(() => (data?.chain === CHAIN_KEYS.ckb ? SYMBOL_KEYS.ckb : SYMBOL_KEYS.godwoken), [data?.chain])

  const [totalVolume, setTotalVolume] = useState()
  const [lowestPriceMarket, setLowestPriceMarket] = useState()
  const [lowestPriceAuction, setLowestPriceAuction] = useState()
  const [highestPriceMarket, setHighestPriceMarket] = useState()
  const [highestPriceAuction, setHighestPriceAuction] = useState()
  const [listedPercent, setListedPercent] = useState(0)
  const [listedItems, setListedItems] = useState()
  const [totalItemCount, setTotalItems] = useState()
  const [ownerCount, setOwnerCount] = useState()
  const [uniqueOwnerCount, setUniqueOwnerCount] = useState(0)
  const [soldCount, setSoldCount] = useState(0)
  const [activeSales, setActiveSales] = useState({ market: 0, auction: 0 })
  const [totalItemsMinted, setTotalItemsMinted] = useState(0)
  const [nftStandard, setNftStandard] = useState('')
  const [marketActive, setMarketActive] = useState(false)

  useEffect(() => {
    if (!data) return

    setNftStandard(data?.standard ?? '')

    function checkMarketStats(analyticsData: { [key: string]: any }) {
      const valuesToCheck = [
        BigInt(analyticsData.activeSales?.auction ?? 0),
        BigInt(analyticsData.activeSales?.market ?? 0),
        BigInt(analyticsData?.lowestPrice?.market ?? 0),
        BigInt(analyticsData?.lowestPrice?.auction ?? 0),
        BigInt(analyticsData?.highestPrice?.market ?? 0),
        BigInt(analyticsData?.highestPrice?.auction ?? 0),
        BigInt(analyticsData?.listedItems ?? 0),
        BigInt(analyticsData?.soldCount ?? 0),
        BigInt(analyticsData?.totalVolume ?? 0),
        BigInt(parseFloat(analyticsData?.listedPercent ?? 0).toFixed(0) || 0)
      ]

      const stats = valuesToCheck.some(value => value > 0n)

      setMarketActive(stats)
    }

    async function fetchAnalytics() {
      try {
        let collectionAddress = data?.itemCollection ?? data?.address

        if (collectionAddress && Array.isArray(collectionAddress)) collectionAddress = collectionAddress.join(',')
        else if (data?.address && Array.isArray(data?.address)) collectionAddress = data.address.join(',')

        const queryParams = new URLSearchParams(`collectionAddress=${collectionAddress}`)

        if (data?.tokenId) queryParams.set('tokenId', data.tokenId)

        const { data: analyticsData } = await axios.get(`/analytics/collection?${queryParams.toString()}`)

        setTotalVolume(analyticsData?.totalVolume)
        setLowestPriceMarket(analyticsData?.lowestPrice.market)
        setLowestPriceAuction(analyticsData?.lowestPrice.auction)
        setHighestPriceMarket(analyticsData?.highestPrice.market)
        setHighestPriceAuction(analyticsData?.highestPrice.auction)
        setListedItems(analyticsData?.listedItems)
        setTotalItems(analyticsData?.totalItems)
        setListedPercent(analyticsData?.listedPercent)
        setOwnerCount(analyticsData?.ownerCount)
        setUniqueOwnerCount(analyticsData?.uniqueOwnerCount)
        setSoldCount(analyticsData?.soldCount)
        setActiveSales({
          auction: analyticsData.activeSales.auction,
          market: analyticsData.activeSales.market
        })
        setTotalItemsMinted(analyticsData?.totalUniqueItems)

        checkMarketStats(analyticsData)
      } catch (error) {
        console.error('Error fetching analytics', error)
      }
    }

    let isMounted = true

    if (isMounted) fetchAnalytics()

    return () => {
      isMounted = false
    }
  }, [data, decimals])

  return (
    <div id="Analytics">
      {marketActive && <div className="market">
        <IconAnalytics size={50} color='var(--primary-color)' className='icon' />
        <h4>Market Stats</h4>

        <dl>
          {(activeSales.market > 0 || activeSales.auction > 0) && <span>
            <dt>Active Sale{activeSales.market + activeSales.auction > 1 ? 's' : ''}</dt>
            {activeSales.market > 0 && <dd>{activeSales.market} (Sale)</dd>}
            {activeSales.auction > 0 && <dd>{activeSales.auction} (Auction)</dd>}
          </span>}

          {(lowestPriceMarket || lowestPriceAuction) && <span>
            <dt>Lowest Price{lowestPriceMarket && lowestPriceAuction ? 's' : ''}</dt>
            {lowestPriceMarket && <dd>{prettyCommaFormat(lowestPriceMarket, decimals)} {symbol} (Sale)</dd>}
            {lowestPriceAuction && <dd>{prettyCommaFormat(lowestPriceAuction, decimals)} {symbol} (Auction)</dd>}
          </span>}

          {(highestPriceMarket || highestPriceAuction) && <span>
            <dt>Highest Price{highestPriceMarket && highestPriceAuction ? 's' : ''}</dt>
            {highestPriceMarket && <dd>{prettyCommaFormat(highestPriceMarket, decimals)} {symbol} (Sale)</dd>}
            {highestPriceAuction && <dd>{prettyCommaFormat(highestPriceAuction, decimals)} {symbol} (Auction)</dd>}
          </span>}

          {totalVolume && <span>
            <dt>Total Volume</dt>
            <dd>{prettyCommaFormat(totalVolume, decimals)} {symbol}</dd>
          </span>}

          {listedPercent > 0 && <span>
            <dt>Items Listed</dt>
            <dd>{listedPercent?.toFixed(2)}% ({listedItems}/{totalItemCount})</dd>
          </span>}

          {soldCount && <span>
            <dt>NFTs Sold</dt>
            <dd>{soldCount}</dd>
          </span>}
        </dl>
      </div>}

      <div className="nft">
        <Storefront size={50} color='var(--primary-color)' />
        <h4>{title} Statistics</h4>

        <dl>
          {data?.royalty && data?.royalty > 0 && <span>
            <dt>{title} Royalty</dt>
            <dd>{getRenderReadyRoyalty(data)}%</dd>
          </span>}

          {uniqueOwnerCount > 0 && <span>
            <dt>Unique Owners</dt>
            <dd>{uniqueOwnerCount}</dd>
          </span>}

          {totalItemsMinted > 0 && <span>
            <dt>Total Items Minted</dt>
            <dd>{totalItemsMinted}</dd>
          </span>}

          {nftStandard !== '' && <span>
            <dt>Contract Standard</dt>
            {/* @ts-ignore */}
            <dd>{nftStandard}</dd>
          </span>}

          {data?.creator && <span>
            <dt>{title} Minter</dt>
            <dd><AuthorAvatar author={data?.creator} displayImage={false} /></dd>
          </span>}

          {!!data?.owner && <span>
            <dt>{title} Owner</dt>
            <dd><AuthorAvatar author={data?.owner} displayImage={false} /></dd>
          </span>}
        </dl>
      </div>
    </div>)
}

export default Analytics
