import type { IConnector } from '../utils/connectors'

import { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { getIpfsHashFromFile } from '../utils/ipfs'
import axios from 'axios'
import { Snackbar, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import EasyCrop from '../components/EasyCrop'
import { IPFS_URL } from '../utils/constants'
import MediaWrapper from '../components/Media/MediaWrapper'
import DOMPurify from 'dompurify'
import { verifyUser } from '../utils/connectors'
import { ProviderKeys } from '../utils/JoyIdConnector'

const UpdateProfile = (props: any) => {
  const { user } = props
  const web3React = useWeb3React()
  const { provider, connector } = web3React
  const [updating, setUpdating] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [coverImgUploading, setCoverImgUploading] = useState(false)
  const [profileImgUploading, setProfileImgUploading] = useState(false)
  const [userProfile, setUserProfile] = useState<{ [key: string]: any } | null>(null)

  const [pcimage, setPCImage] = useState<null | string>(null)

  const handleImageUpload = async (e: any) => {
    setPCImage(URL.createObjectURL(e.target.files[0]))
  }

  useEffect(() => {
    setUserProfile(null)
    if (!user) return

    setUserProfile({
      ...user,
      xLink: user?.xLink || user?.twitterLink
    })
  }, [user])
  const handleClose = (event: any, reason: string) => {
    if (reason === 'clickaway') return
    setOpenSnackbar(false)
  }

  async function updateProfile() {
    try {
      setUpdating(true)

      const walletConnection = connector as IConnector['connection']

      let signer
      // @ts-ignore
      if (walletConnection?.isJoyId && 'getProvider' in walletConnection) {
        signer = walletConnection.getProvider(ProviderKeys.ckb)
      } else if (provider) {
        signer = await provider?.getSigner()
      }

      // @ts-ignore
      const verified = await verifyUser(walletConnection, signer)

      if (!verified) {
        setUpdating(false)
        setSnackBarMessage('Error verifying address')
        setOpenSnackbar(true)
        return
      }

      const result = await axios.post(
        '/user/update/profile',
        userProfile
      )

      if (!result) throw new Error('Error posting to server')

      setUpdating(false)
      setUserProfile(userProfile)
      setSnackBarMessage('Success')
      setOpenSnackbar(true)
    } catch (error: any) {
      console.error('Error updating account!', error)
      setUpdating(false)
      setSnackBarMessage(error.response.data.message)
      setOpenSnackbar(true)
    }
  }

  function handleProfileFile(event: any) {
    const fileType = event.target.files[0].type.split('/')[0]
    if (fileType === 'image') {
      setProfileImgUploading(true)
      getIpfsHashFromFile(event.target.files[0]).then((hash) => {
        setUserProfile((prevState: { [key: string]: any }) => ({ ...prevState, profilePic: `${IPFS_URL}${hash}` }))

        setProfileImgUploading(false)
      })
    }
  }
  function handleCoverFile(event: any) {
    const fileType = event.target.files[0].type.split('/')[0]
    if (fileType === 'image') {
      setCoverImgUploading(true)
      getIpfsHashFromFile(event.target.files[0]).then((hash) => {
        setUserProfile((prevState: { [key: string]: any }) => ({ ...prevState, profileCover: `${IPFS_URL}${hash}` }))
        setCoverImgUploading(false)
      })
    }
  }

  const imgState = {
    image: userProfile?.profileCover,
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 3 / 1,
    croppedAreaPixels: {}
  }

  const drawImage = () => { }

  const onCropChange = (crop: any) => {
    imgState.crop = crop
  }

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    console.info('croppedArea: ', croppedArea)
    console.info('croppedAreaPixels: ', croppedAreaPixels)
    imgState.croppedAreaPixels = croppedAreaPixels
  }

  const onZoomChange = (zoom: any) => {
    imgState.zoom = zoom
  }

  return (
    <>
      {userProfile ?
        <section id='UpdateProfile' className='author-area explore-area'>
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='card no-hover text-center'>
                {/* Card Caption */}
                {/* Card Body */}
                <div className='card-body item-form'>
                  <div className='row'>
                    <div className='col-12 mb-4'>
                      <h1>Update Profile</h1>
                      <h3>Account</h3>
                      <span>{userProfile.address}</span>
                    </div>
                  </div>

                  <div className='row mt-4'>
                    <div className='col-12 col-md-6 input-group'>
                      <div className='input-group'>
                        <label htmlFor='name'>Username:</label>
                        <input
                          type='text'
                          className='form-control'
                          value={userProfile?.name || ''}
                          id='name'
                          name='name'
                          onChange={e => setUserProfile((prevState: { [key: string]: any }) => ({ ...prevState, name: e.target.value }))} />
                      </div>

                      <div className='input-group'>
                        <label htmlFor='facebook'>Facebook:</label>
                        <input
                          type='text'
                          className='form-control'
                          value={userProfile?.facebookLink || ''}
                          id='facebook'
                          name='facebook'
                          onChange={e => setUserProfile((prevState: { [key: string]: any }) => ({ ...prevState, facebookLink: e.target.value }))}
                        />
                      </div>

                      <div className='input-group'>
                        <label htmlFor='x'>X:</label>
                        <input
                          type='text'
                          className='form-control'
                          value={userProfile?.xLink || ''}
                          id='x'
                          name='x'
                          onChange={e => setUserProfile((prevState: { [key: string]: any }) => ({ ...prevState, xLink: e.target.value }))}
                        />
                      </div>
                    </div>

                    <div className='col-12 col-md-6 input-group'>
                      <label htmlFor='bio'>Bio</label>
                      <textarea
                        rows={10}
                        value={userProfile?.bio || ''}
                        id='bio'
                        name='bio'
                        onChange={e => setUserProfile((prevState: { [key: string]: any }) => ({ ...prevState, bio: e.target.value }))}
                      />
                    </div>

                    <div className='col-12 col-md-6 mt-4'>
                      <div className='inputField mt-2'>
                        <input type='file' id='cover_input_file' accept='image/*' multiple={false} onChange={handleCoverFile} style={{ display: 'none' }} />
                        {coverImgUploading
                          ? <button className='btn btn-bordered'>Uploading ...</button>
                          : <button className='btn btn-bordered' onClick={() => document.getElementById('cover_input_file')?.click()}>Upload Cover Image</button>}
                      </div>
                    </div>
                    <div className='col-12 col-md-6 mt-4'>
                      <div className='inputField'>
                        <input type='file' id='profile_input_file' accept='image/*' multiple={false} onChange={handleProfileFile} style={{ display: 'none' }} />
                        {profileImgUploading
                          ? <button className='btn btn-bordered'>Uploading ...</button>
                          : <button className='btn btn-bordered' onClick={() => document.getElementById('profile_input_file')?.click()}>Upload Profile Image</button>}
                      </div>
                    </div>
                  </div>

                  <div className='row mt-1 mb-4 text-center'>
                    <h2 className='col-12'>Preview</h2>

                    <div className='card no-hover col-12'>
                      <MediaWrapper className='banner' src={userProfile?.profileCover} alt={`Banner for ${userProfile?.name}`} style={{ width: '100%', maxHeight: '250px', objectFit: 'cover' }} />
                      {/* <EasyCrop image={newProfileCoverSrc} /> */}

                      <MediaWrapper src={userProfile?.profilePic} alt={`Profile Picture for ${userProfile.name}`} style={{ maxWidth: '150px', maxHeight: '150px', objectFit: 'contain', margin: '-4rem auto 0' }} />
                    </div>

                    <h4 className='col-12'>{userProfile?.name}</h4>

                    {/* Render Bio*/}
                    <p className='col-12 m-0' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(userProfile?.bio?.replace(/\n/g, "<br />") || '') }} />

                    {/* Social Icons */}
                    <div className='social-icons d-flex justify-content-center my-3 w-100'>
                      {userProfile?.facebookLink && userProfile.facebookLink.startsWith('https://facebook.com/') &&
                        <a
                          className='facebook'
                          href={userProfile.facebookLink}
                          target='_blank'
                          rel="noopener"
                        >
                          <i className='fab fa-facebook-f' />
                          <i className='fab fa-facebook-f' />
                        </a>
                      }
                      {userProfile?.xLink && userProfile.xLink.startsWith('https://twitter.com/') &&
                        <a
                          className='twitter'
                          href={userProfile.xLink}
                          target='_blank'
                          rel="noopener"
                        >
                          <svg
                            style={{ width: '25px', height: '100%', color: '#000' }}
                            width="1200" height="1227" viewBox="0 0 1200 1227" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="#fff" />
                          </svg>
                          <svg
                            style={{ width: '25px', height: '100%' }}
                            width="1200" height="1227" viewBox="0 0 1200 1227" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="#fff" />
                          </svg>
                        </a>
                      }
                      {userProfile.googleLink && <a className='google-plus' href='#'>
                        <i className='fab fa-google-plus-g' />
                        <i className='fab fa-google-plus-g' />
                      </a>}
                      {userProfile.vineLink && <a className='vine' href='#'>
                        <i className='fab fa-vine' />
                        <i className='fab fa-vine' />
                      </a>}
                    </div>
                  </div>

                  {updating
                    ? <div className='btn btn-solid-green mt-1' onClick={() => updateProfile()}>Updating ...</div>
                    : <div className='btn btn-solid-green mt-1' onClick={() => updateProfile()}>Submit Updates</div>}
                </div>
              </div>
            </div>
          </div>
        </section >
        : <div className="container text-center"><button className='connect-wallet mt-4 d-inline' onClick={props.connectAccount}>Connect Wallet</button> to login and update account</div>
      }

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleClose}
        message={snackBarMessage}
        action={
          <span onClick={event => handleClose(event, 'Close Snackbar')}>
            <IconButton size='small' aria-label='close' color='inherit' >
              <CloseIcon fontSize='small' />
            </IconButton>
          </span>
        }
      />
    </>

  )
}

export default UpdateProfile
